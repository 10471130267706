import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlTree} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, tap, switchMap} from 'rxjs/internal/operators';

@Injectable()
export class AuthGuardService implements CanActivate {

    private loginPage = '/';

    constructor(private authenticationService: AuthenticationService, private router: Router) {}

    // canActivate(
    //     next: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //     return this.checkLogin(state.url);
    // }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.authenticationService.isLogged){
          return true;
        }

        if (state.url.includes('fail=1') ) {
            return true;
        }
    
        //let url = state.url.startsWith(this.loginPage) ? this.homePage : state.url;
        let access_token = route.queryParamMap.get("access_token");
        if (access_token) {
          this.authenticationService.access_token = access_token;
          let url = state.url.replace(/access_token=[^&]+&?/, '');
    
          return this.authenticationService.login(access_token).pipe(
            tap(e => {
              if (!e) {
                this.authenticationService.lastUrl = url;
                this.router.navigate([this.loginPage],{queryParams : {fail : 1}});
              }
              else this.router.navigateByUrl(url);
            })
          );
        }
        this.authenticationService.loginAuth();
        return false;
      }




    //   checkLogin(url: string): Observable<boolean> | boolean {
    //     if (this.authenticationService.isLogged) 
    //         return true;
    //     if (url != this.login_page)
    //         this.authenticationService.lastUrl = url;
    //     let sub :Subject<any> = new Subject<any>();
    //     let ob:Observable<any> = sub.asObservable();
        
    //     let p =  ob.pipe(
    //         catchError((err) => {
    //             this.router.navigated = false;
    //             //this.authenticationService.lastUrl = url;
    //             console.log("error");
    //             if (url == this.login_page)
    //                 return of(false)
    //             this.router.navigate([this.login_page]);
    //             return of(false);
    //         }),
    //         map(response => {
    //             console.log(response);
    //             if(response) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         })
    //     );
    //     this.authenticationService.checkAuthentication().then((e) => {
    //         sub.next(e);
    //     }).catch(e => { sub.error(e);});
    //     return p;
    // }
}
