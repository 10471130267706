import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class QuotasService extends BaseService {

  private base:string = '/api/quotas';

  constructor(private httpClient:HttpClient, private authenticationService:AuthenticationService, public router:Router, public snackbar:MatSnackBar) {
    super(router, snackbar);
   }

   public list():Observable<any>
   {
    return this.httpClient.get(this.base + "/list").pipe(catchError(e => this.handleError(e)));
   }
   public get():Observable<any>
   {
    return this.httpClient.get(this.base + "/get").pipe(catchError(e => this.handleError(e)));
   }

   public add(transaction:any):Observable<any>
   {
    return this.httpClient.post(this.base + "/add", transaction).pipe(catchError(e => this.handleError(e)));
   }

   public tryadd(transaction:any):Observable<any>
   {
     return this.httpClient.post(this.base + "/try_add", transaction).pipe(catchError(e => this.handleError(e)));
   }
   
   public try(date:string):Observable<any>
   {
    return this.httpClient.get(this.base + "/try?date="+date).pipe(catchError(e => this.handleError(e)));
   }
   public canGive(type:string):Observable<any>
   {
     return (this.httpClient.get(this.base + "/try_cangive/" + type).pipe(catchError(e => this.handleError(e))));
   }

   public remove(id:number):Observable<any>
   {
     return this.httpClient.get(this.base + '/remove/' + id).pipe(catchError(e => this.handleError(e)));
   }


   public getTransaction(id:number):Observable<any>
   {
      return this.httpClient.get(this.base + "/" + id).pipe(catchError(e => this.handleError(e)));
   }
   public updateTransaction(id:number, update:any):Observable<any>
   {
     return this.httpClient.post(this.base + "/update/" + id, update).pipe(catchError(e => this.handleError(e)));
   }


}
