import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InfoService } from 'src/app/services/info.service';

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.scss']
})
export class RuleComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<RuleComponent>, public authenticationService:AuthenticationService, public infoService:InfoService) { }

  ngOnInit() {
  }

  public accept()
  {
    this.infoService.accept().subscribe(e => {
      this.dialogRef.close();
      localStorage.setItem('accept', "true");
    })
  }

}
