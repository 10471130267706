import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {MatSnackBar} from '@angular/material';
import {Router} from "@angular/router";
import {catchError, tap, switchMap, map} from 'rxjs/internal/operators';
import {BaseService} from './base.service';
import { of, throwError } from 'rxjs';
import { Observable } from "rxjs-compat";


export const OAuthSetting = {
    appId: 'f2fe7947-c0de-4aa8-afa8-8e0f6ef07204',
    redirectUri: window.location.protocol + '//' + window.location.host,
    scopes: [
        'user.read',
        'user.readbasic.all'
    ]
};

@Injectable({providedIn: 'root'})
export class AuthenticationService extends BaseService {

    public isLogged = false;
    public lastUrl = "/list";
    public error: string;
    public user: any;
    public access_token = undefined;
    public authUrl = "https://auth.iphub.me/login";


    constructor(private http: HttpClient, public router: Router, public snackBar: MatSnackBar) {
        super(router, snackBar);
    }

    public loginAuth()
    {
        let url = new URL( document.location.origin + this.lastUrl);
        let httpParam:HttpParams = new HttpParams();//.set('query', url.searchParams!.toString());
        // if(redirectTo)
        //   httpParam = httpParam.set('redirect',  window.origin + redirectTo)
        // else
        httpParam = httpParam.set("redirect", window.origin + url.pathname)
        window.location.href = this.authUrl + "?" + httpParam.toString();
      }
    

    // async signIn(): Promise<boolean> {

    //     try {
    //         console.log(OAuthSetting.redirectUri)
    //         this.error = undefined;

    //         this.access_token = await this.msalService.acquireTokenSilent(OAuthSetting.scopes).catch(e =>{});
    //         let result = null
    //         if (!this.access_token)
    //              result = await this.msalService.loginPopup(OAuthSetting.scopes);

    //         if (result || this.access_token) {

    //             let msUser = this.msalService.getUser();
    //             if (!this.access_token)
    //                 this.access_token = await this.msalService.acquireTokenSilent(OAuthSetting.scopes);
                

    //             this.user = await this.http.post('/auth/login', {access_token: this.access_token, email: msUser.displayableId}).toPromise();

    //             localStorage.setItem('access_token', this.user.token);
    //             this.isLogged = true;
    //             this.error = undefined;
                
    //             return Promise.resolve(true);
    //         } else {
    //             return Promise.resolve(false);
    //         }
    //     } catch (ex) {
    //         this.user = undefined;
    //         this.isLogged = false;
    //         this.error = this.getErrorMessage(ex);
    //         this.error = this.error.split('|')[1];
    //         return Promise.resolve(false);
    //     }
    // }

//     public async checkAuthentication() {
// //        this.isLogged = false;
//         this.access_token = await this.msalService.acquireTokenSilent(OAuthSetting.scopes).catch(e =>{});
//         if (!this.access_token)
//         {
//             this.msalService.loginRedirect(OAuthSetting.scopes);
//             return Promise.reject(false)
//         }
// //            return Promise.reject(false);
//         let msUser = this.msalService.getUser();
//         return  this.http.post('/auth/login', {access_token: this.access_token, email: msUser.displayableId}).pipe(
//                 catchError((e) => {return throwError(false);}),
//                 map((response: any) => {
//                     if (response)
//                     {
//                         this.user = response;
//                         localStorage.setItem('access_token', this.user.token);
//                         this.isLogged = true;
//                         this.router.navigate([this.lastUrl]);
//                         return (true);
//                     }
//                     return false;
//                 })
//             ).toPromise();
//         // return this.http.get('/api/auth/check_token').pipe(
//         //     catchError((e) => {return throwError(false);}),
//         //     map((response: any) => {
//         //         if (response)
//         //         {
//         //             this.user = response.user;
//         //             this.isLogged = true;
//         //             this.router.navigate([this.lastUrl]);
//         //             return (false);
//         //         }
//         //         return true;
//         //     })
//         // ).toPromise();
//     }

    public login(access_token:string):Observable<boolean> {
      return   this.http.post('/auth/login', {access_token: access_token}).pipe(
            catchError((e) => {return throwError(false);}),
            map((response: any) => {
                if (response)
                {
                    this.user = response;
                    localStorage.setItem('access_token', this.user.token);
                    this.isLogged = true;
                    this.router.navigate([this.lastUrl]);
                    return (true);
                }
                return false;
            })
        )
    }

    public logout() {
        localStorage.removeItem("access_token");
        
        this.isLogged = false;
//        this.router.navigate(['/']);
        // this.msalService.logout();
        return of(true);
    }
}
