import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './libraries/material.module';
import {  OAuthSetting } from './services/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from './services/jwt-interceptor.service';
import { ListComponent } from './components/list/list.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AddComponent } from './components/add/add.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import {OverlayModule} from "@angular/cdk/overlay";
import { LoaderComponent } from './components/loader/loader.component';
import { AdminComponent } from './components/admin/admin.component';
import { AuthGuardAdminService } from './services/auth-guard-admin.service';
import { RuleComponent } from './components/list/rule/rule.component';
import { DeleteDialogComponent } from './components/list/delete/delete-dialog.component';
import { UpdateComponent } from './components/update/update.component';

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    AddComponent,
    LoginComponent,
    LoaderComponent,
    AdminComponent,
    RuleComponent,
    DeleteDialogComponent,
    UpdateComponent
  ],
  imports: [
    HttpClientModule,
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    OverlayModule
  ],
  providers: [
    AuthGuardService,
    AuthGuardAdminService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }

  ],
  entryComponents: [RuleComponent, DeleteDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
