import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuotasService } from 'src/app/services/quotas.service';
import * as moment from  'moment';
import { MatSnackBar, MatSnackBarModule } from '@angular/material';
import { Router } from '@angular/router';
import { startWith } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  public formReason: FormGroup;
  public formType: FormGroup;
  public formTransaction: FormGroup;
  public my_quotas = undefined;
  public quotas = undefined;
  public max:number = undefined;
  public min_date = moment().subtract(7, 'day');
  public max_date = moment().endOf('year');

  public f_calc = {
    reunion_cse : () => {return (this.quotas.quotas.cse || 0) + (this.quotas.quotas.static_cse || 0) +  (this.quotas.quotas.annual_cse || 0);},
    prepa_cse : () => {return (this.quotas.quotas.cse || 0) + (this.quotas.quotas.static_cse || 0);}
  }
  public f_rest = {
    reunion_cse : (n) => {
      let out = {quotas : {}};
      if (this.quotas.quotas.annual_cse)
      {
        out.quotas['cse'] = -n;
        this.quotas.quotas.annual_cse -= n;
        if (this.quotas.quotas.annual_cse < 0)
        {
          out.quotas['cse'] = -(n + this.quotas.quotas.annual_cse);
          n = Math.abs(this.quotas.quotas.annual_cse);
        }
        else n = 0;
      }
      if (!n)
        return out;
      if (this.quotas.quotas.cse)
      {
        
        this.quotas.quotas.cse -= n;
        if (this.quotas.quotas.cse < 0)
        {
          out.quotas['cse'] =  (out.quotas['cse'] || 0)  -(n + this.quotas.quotas.cse);
          n = Math.abs(this.quotas.quotas.cse);
        }
        else {
          out.quotas['cse'] =  (out.quotas['cse'] || 0) - n;
          n = 0;
        }
      }
      if (!n)
        return out;
      out.quotas['static_cse'] = -n;
      return out;
    },
    prepa_cse : (n) => {
      let out = {quotas : {}};

      if (this.quotas.quotas.cse)
      {
        out.quotas['cse'] = -n;
        this.quotas.quotas.cse -= n;
        if (this.quotas.quotas.cse < 0)
        {
          out.quotas['cse'] = -(n + this.quotas.quotas.cse);
          n = Math.abs(this.quotas.quotas.cse);
        }
        else n = 0;
      }
      if (!n)
        return out;
      out.quotas['static_cse'] = -n;
      return out;
    }
  }

  constructor(private quotasService:QuotasService, public authenticationService:AuthenticationService, public snackBar:MatSnackBar, public router:Router) { 
  }

  ngOnInit() {


    this.formReason = new FormGroup({
      reason: new FormControl(undefined, [Validators.required]),
      date : new FormControl({value : this.min_date, disabled: true}, [Validators.required])
    });
    this.formReason.get("date").valueChanges.pipe(startWith(this.min_date)).subscribe(e => {
      
      this.quotasService.try(moment(e).format('YYYY-MM-DD')).subscribe((e) =>{
      
        this.my_quotas = e;
        let total = 0;
        for (let i  in this.my_quotas.quotas)
        {
          // if (i == 'annual_cse')
          //   continue;
          // this.my_quotas.quotas[i] += (i in this.my_quotas.used_quotas_month) ? this.my_quotas.used_quotas_month[i] : 0;
           total += this.my_quotas.quotas[i].quota || 0;
        }
        this.my_quotas.quotas.total = total ;//+ (this.my_quotas.quotas.annual_cse|| 0);
  //      this.quotas = this.my_quotas;
      })
    });
    this.formType = new FormGroup({
      type: new FormControl(undefined, [Validators.required])
    });
    // this.formTransaction = new FormGroup({
    //   hours: new FormControl(undefined, [Validators.required])
    // });


    this.formReason.get('reason').valueChanges.subscribe(e => {
      this.max = undefined;
      (this.formType.get("type") as FormControl).setValue(undefined, {emitEvent: false});

      if (e == 'give')
      {
        this.formReason.get('date').disable({emitEvent : false});
        this.formTransaction = new FormGroup({
          hours : new FormControl(undefined, [Validators.required, Validators.max(this.max)]),
          give : new FormControl(undefined, [Validators.required]),
          //date : new FormControl(moment(), [Validators.required])
        });
        this.formTransaction.get('give').valueChanges.subscribe(e => {
          if (this.formType.get('type').value == 'cse' &&  this.my_quotas.canGive)
          {
            let u = this.my_quotas.canGive.find(u => u.id == e );
            if (u)
            {
              this.max = Math.min(55.5 + u.mounth_usage, this.my_quotas.quotas.static_cse.quota);
              (this.formTransaction.get('hours') as FormControl).setValidators([Validators.max(this.max), Validators.required]);
              (this.formTransaction.get('hours') as FormControl).updateValueAndValidity({emitEvent: false});        
            }
              
          }
        })
      }
      else if (e == 'declaration')
      {
        this.formReason.get('date').enable({emitEvent : false});
        this.formTransaction = new FormGroup({
          hours : new FormControl(undefined, [Validators.required]),
          time : new FormControl(undefined, [Validators.required])
        });
      }
      else if (e == 'guest')
      {
        this.formTransaction = new FormGroup({
          hours : new FormControl(undefined, [Validators.required]),
          time : new FormControl(undefined, [Validators.required]),
          guest : new FormControl(undefined, [Validators.required])
        });
        this.formType.get('type').setValue('annual_ds');
        //this.formType.get('type').disable({emitEvent : false});
        this.formReason.get('date').enable({emitEvent : false});
      }
    });
    
    this.formType.get('type').valueChanges.subscribe(e => {
      // if (e != 'cse')
      // {
        this.max = this.my_quotas.quotas[e].quota;
        (this.formTransaction.get('hours') as FormControl).setValidators([Validators.max(this.max), Validators.required]);
        (this.formTransaction.get('hours') as FormControl).updateValueAndValidity({emitEvent: false});
      // }
      if (this.formReason.get('reason').value == 'give')
      {
        this.max = e == 'cse' ? undefined : this.max;//this.my_quotas.quotas.static_cse.quota : this.max;
        // load can give
        this.quotasService.canGive(e).subscribe(data => {
          this.my_quotas.canGive = data;
        });

      }

    })

  }



  public saveTransaction()
  {
    if (!this.formReason.valid || !this.formTransaction.valid || !this.formType.valid)
    {
      this.snackBar.open('Vérifier les valeurs requises', 'FERMER', {duration : 20000,panelClass:'error'});
      return;
    }


    // let transaction = [!(this.formType.get('type').value in  this.f_rest) ? {'quotas' : {[this.formType.get('type').value] : -this.formTransaction.get('hours').value}} : this.f_rest[this.formType.get('type').value](this.formTransaction.get('hours').value)];
    // transaction[0].date = this.formTransaction.get('date').value.format('YYYY-MM-DD');
    // if (this.formReason.get('reason').value == 'substitute')
    // {

    //   transaction[0].by = this.my_quotas.user;
    //   transaction[0].user_id = this.formReason.get('substitute').value;
    //   transaction[0].type = this.formType.get('type').value;
    // }
    let transaction = new Object();
    transaction['type'] = this.formReason.get('reason').value;
    if (this.formReason.get('reason').value == 'give')
      {
        //let transaction = new Object();
        //transaction['user_id'] = this.my_quotas.user.id;
        transaction['to'] = this.formTransaction.get('give').value;
        transaction['hours'] = this.formTransaction.get("hours").value;
        transaction['kind'] = this.formType.get('type').value;
      }
      if (this.formReason.get('reason').value == 'declaration' || this.formReason.get('reason').value == 'guest')
      {
        //transaction['user_id'] = this.my_quotas.user.id;
        transaction['hours'] = this.formTransaction.get("hours").value;
        // if ( this.authenticationService.user.work_time == 'FJ' && (transaction['hours'] % 4))
        // {
        //   this.snackBar.open('FJ doivent prendres des tranches de 4 heures', 'FERMER', {duration : 20000,panelClass:'error'});
        //   return;
        // }
        transaction['time'] = this.formTransaction.get("time").value;
        transaction['kind'] = this.formType.get('type').value;
        transaction['date'] = this.formReason.get('date').value.format('YYYY-MM-DD');
        if ( this.formReason.get('reason').value == 'guest')
          transaction['guest'] = this.formTransaction.get('guest').value
      }
    this.quotasService.tryadd(transaction).subscribe((e) => {

      this.router.navigate(['/', 'list']);
      this.snackBar.open('Déclaration enregistrée', 'FERMER', {duration : 5000});
    }, e => {    this.snackBar.open('Vérifier les valeurs. ex : FJ seulement des tranches de 4 heures', 'FERMER', {duration : 20000,panelClass:'error'});
    });
    console.log(transaction);
  }

}
