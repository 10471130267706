import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InfoService extends BaseService {

  private base:string = "/api/info"

  constructor(public httpClient:HttpClient, public router: Router, public snackBar: MatSnackBar) { super(router, snackBar);}

  public accept()
  {
    return this.httpClient.post(this.base, {}).pipe(catchError(e => this.handleError(e)));
  }
}
