import { Component, OnInit } from '@angular/core';
import { QuotasService } from 'src/app/services/quotas.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

  public formTransaction:FormGroup;
  public transaction:any;
  public moment = moment;
  public my_quotas:any;
  public max:number = undefined;
  constructor(private snackBar:MatSnackBar, private quotasService:QuotasService, public authenticationService:AuthenticationService, public activatedRoute:ActivatedRoute, public router:Router) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(p => {
      this.quotasService.getTransaction(+p.get('id')).subscribe(e => {
        this.transaction = e;
        this.quotasService.try(moment.utc(this.transaction.date).format('YYYY-MM-DD')).subscribe((e) =>{
          this.my_quotas = e;
          this.initForm();
        });        
      });
    });
  }

  public initForm()
  {
    this.max = this.my_quotas.quotas[this.transaction.type].quota-this.transaction.quotas[this.transaction.type];
    let g = {
        hours : new FormControl(-this.transaction.quotas[this.transaction.type], [Validators.required, Validators.max(this.max)]),
        time : new FormControl(this.transaction.time, [Validators.required]),
        guest : new FormControl(this.transaction.guest, [Validators.required])
    };
    if (this.transaction.guest === undefined)
      delete g.guest;
    this.formTransaction = new FormGroup(g);
  }

  public saveTransaction()
  {
    if (!this.formTransaction.valid)
    {
      this.snackBar.open('Vérifier les valeurs requises', 'FERMER', {duration : 20000,panelClass:'error'});
      return;

    }
    let transaction ={};
    transaction['hours'] = this.formTransaction.get("hours").value;
    // if ( this.authenticationService.user.work_time == 'FJ' && (transaction['hours'] % 4))
    // {
    //   this.snackBar.open('FJ doivent prendres des tranches de 4 heures', 'FERMER', {duration : 20000,panelClass:'error'});
    //   return;
    // }
    transaction['kind'] = this.transaction.type;
    transaction['time'] = this.formTransaction.get('time').value;
    if (this.transaction.guest)
      transaction['guest'] = this.formTransaction.get("guest").value;
    let tr
    this.quotasService.updateTransaction(this.transaction._id, transaction).subscribe((e) => {

      this.router.navigate(['/', 'list']);
      this.snackBar.open('Déclaration mise à jour', 'FERMER', {duration : 5000});
    }, e => {    this.snackBar.open('Vérifier les valeurs. ex : FJ seulement des tranches de 4 heures', 'FERMER', {duration : 20000,panelClass:'error'});
    });


  }

}
