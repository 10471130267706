import {OverlayContainer} from '@angular/cdk/overlay';
import {Component, OnInit, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Theme, ThemeService} from 'src/app/services/theme.service';
import { AuthenticationService } from './services/authentication.service';
import { FormControl } from '@angular/forms';
import { AdminService } from './services/admin.service';
import { QuotasService } from './services/quotas.service';
import { LoaderService } from './services/loader.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {



  public theme: Theme;
  public events: string[] = [];
  public themes: Theme[] = [];

  private formFile:FormControl = new FormControl('',[]);
  public isLoading:Subject<boolean> = this.loaderService.isLoading;

  constructor(
      private themeService: ThemeService,
      private adminService:AdminService,
      public loaderService:LoaderService,
      public authService:AuthenticationService,
      private overlayContainer: OverlayContainer,
      public router: Router
  ) {
    this.theme = this.themeService.getDefaultTheme();
    this.overlayContainerTheming(this.theme.value);
  }

  public ngOnInit() {
    this.themes = this.themeService.getThemes();
    this.themeService.getTheme().subscribe((next) => {
      this.theme = next;
    });

  }

  public ngAfterViewInit(): void {
    ///this.quotasService.list().subscribe(e => console.log());
  }

  
  public onFileChange()
  {
    
    if(false === this.formFile.valid) {
      return;
    }
   // this.adminService.loadQuotas(this.formFile.value).subscribe(e => console.log(e));
  }

  public toggleTheme(value: string) {
    this.overlayContainerTheming(value);
    this.themeService.setTheme(value);
  }

  private overlayContainerTheming(themeClassName: string) {
    const cl = this.overlayContainer.getContainerElement().classList;

    if (cl.length > 1) {
      this.overlayContainer.getContainerElement().classList.remove(cl.item(cl.length - 1));
    }

    this.overlayContainer.getContainerElement().classList.add(themeClassName);
  }

}
