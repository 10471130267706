import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {

  private base:string = '/api/admin';

  constructor(private httpClient:HttpClient, private authenticationService:AuthenticationService, public router:Router, public snackbar:MatSnackBar) {
    super(router, snackbar);
   }

   public loadQuotas():Observable<any>
   {
    let params = new HttpParams().set('token',   this.authenticationService.access_token);
    return this.httpClient.get(this.base + "/loadquotas?" + params.toString()).pipe(catchError(e => this.handleError(e)));
   }


   public loadSubstitutes(file:FormData):Observable<any>
   {
    return this.httpClient.post(this.base + "/substitutes", {file : file, token: this.authenticationService.access_token}).pipe(catchError(e => this.handleError(e)));
   }
   public listTransaction(filters, page:number, limit:number):Observable<any>
   {
    return this.httpClient.post(this.base + "/list", {filters, limit, page}).pipe(catchError(e => this.handleError(e)));
   }


   public exportTransaction(filters):Observable<any>
   {
    return this.httpClient.post(this.base + "/export", {filters}).pipe(catchError(e => this.handleError(e)));
   }

   public downloadTransaction(filename)
   {
    let params = new HttpParams().set('token',  localStorage.getItem('access_token'));

    let a = document.createElement('a');
    a.href = this.base + "/download/" + filename + "?" + params.toString();
    a.download = "export-" + filename + ".xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

//    return this.httpClient.get(this.base + "/download/" + filename).pipe(catchError(e => this.handleError(e)));
   }


   public listUsers():Observable<any>
   {
     return this.httpClient.get(this.base + '/users').pipe(catchError(e => this.handleError(e)));
   }

}
