import {HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {throwError} from 'rxjs';

@Injectable()
export abstract class BaseService {


    constructor(public router: Router, public snackBar: MatSnackBar) {
    }

    public handleNext(response: any) {
        return response;
    }

    public handleError(error) {
        if (error.status && error.status === 403) {
            this.router.navigate(['/login']);
            this.snackBar.open('Your session has expired. Please reconnect to the app', '', {duration: 10000, verticalPosition: 'top'});
        }

        return throwError(this.getErrorMessage(error));
    }

    public snackHttpError(error: HttpErrorResponse) {
        this.snackBar.open('HTTP Error : ' + this.getErrorMessage(error), '', {duration: 10000, verticalPosition: 'top'});
        return throwError(error);
    }

    public getErrorMessage(error) {
        if (error instanceof String) {
            return error;
        } else if (error.error) {
            return error.error;
        } else if (error.message) {
            return error.message;
        } else {
            return error.toString();
        }
    }
}
