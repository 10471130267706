import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './components/list/list.component';
import { AddComponent } from './components/add/add.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AdminComponent } from './components/admin/admin.component';
import { AuthGuardAdminService } from './services/auth-guard-admin.service';
import { UpdateComponent } from './components/update/update.component';


const routes: Routes = [
  {
    path:'',
    component: LoginComponent,
    canActivate: [AuthGuardService]
  },
  {
      path: 'list', 
      component: ListComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'add', 
      component: AddComponent,
      canActivate: [AuthGuardService]
    },
    {
      path: 'update/:id', 
      component: UpdateComponent,
      canActivate: [AuthGuardService]
    },
      {

    path: 'admin', 
    component: AdminComponent,
    canActivate: [AuthGuardService, AuthGuardAdminService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
