import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { map, catchError, startWith, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { of } from 'rxjs';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit{

  private dataLength = 0;
  public columnTemp = [
    'date',
    'user',
    'type',
    'quotas'
  ];
  private chartDisplay : any = {
    'reunion': 'CSE',
    'preparation': 'CSE',
    'give_cse': 'Don CSE',
    'give_ds': 'Don DS',
//    'give_treasurer': 'Don Trésosier',

    'ds': 'DS',
    'annual_ds': 'DS',
    'cse': 'CSE',
    'static_cse' : 'CSE',
    'treasurer' : 'Trésorier',
    'assistant_treasurer' : 'Trésorier adj.',
    'secretary_cse' : 'Secrétaire CSE',
    'assistant_secretary_cse' : 'Secrétaire CSE adj.',
    'secretary_cssct' : 'Secrétaire CSSCT',
    'assistant_secretary_cssct' : 'Secrétaire CSSCT adj.',
    'cssct' : 'CSSCT',
    'rscse' : 'RSCSE',
    'com_eg_pro' : 'Commission égalité pro',
    'com_form' : 'Commission Formation',
    'com_carriere': 'Commission carrière',
    'com_eco' : 'Commission Eco'
  }
  public users:any[] = [];

  public formGroup:FormGroup;

  private data: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  private current_filter:any = {};

  constructor(private adminService:AdminService) { }


  ngOnInit() {
    this.initForm();
    this.listUsers();
//    this.adminService.loadQuotas().subscribe(e => console.log(e));
  }

  private initForm()
  {
    let first = moment(moment().year() + "-01-01", 'YYYY-MM-DD');
    this.current_filter = {start_date : first.format('YYYY-MM-DD')};
    let group = {
      start_date : new FormControl(first),
      end_date : new FormControl(undefined),
      users : new FormControl(undefined),
      types : new FormControl(undefined)
    }

    this.formGroup = new FormGroup(group);
    this.formGroup.valueChanges.subscribe(
      (e) => {
        let out = {
                start_date : e.start_date ? e.start_date.format('YYYY-MM-DD') : undefined,
                end_date : e.end_date ? e.end_date.format('YYYY-MM-DD') : undefined,
                users : e.users || undefined,
                types : e.types || undefined
              }
        this.loadData(this.current_filter = out);
      }
    );

  }
  public listUsers()
  {
    this.adminService.listUsers().subscribe(data => {
      this.users = data;
    })
  }

  public ngAfterViewInit()
  {
    this.paginator.page.pipe(startWith({})).subscribe(e => this.loadData(this.current_filter));

  }

  public loadData(filters = {})
  {
    
    this.adminService.listTransaction(filters,  this.paginator.pageIndex *this.paginator.pageSize ,
      this.paginator.pageSize).pipe(map((response:any) => {
      this.dataLength = response.count;
      response.data = response.data.map(e => {
          e.date = moment(e.date).format('YYYY-MM-DD');
          e.type = this.chartDisplay[e.type];
          e.user = e.user[0];
          if (e.by && this.columnTemp.indexOf('of') === -1)
            this.columnTemp.push('substitute');
          if(e.from && this.columnTemp.indexOf('from') === -1)
            this.columnTemp.push('from');
            if (e.to && this.columnTemp.indexOf('to') === -1)
            this.columnTemp.push('to');
            if (e.guest && this.columnTemp.indexOf('guest') === -1)
            this.columnTemp.push('guest');

          e.quotas =  e.type == 'reunion' || e.type == 'preparation' ? ((e['quotas'].cse || 0) +(e['quotas'].static_cse ||0)) : Object.values(e['quotas'])[0];
          return e;

      });
      return response;
    }), catchError(() =>  of([]))).subscribe((response) => {
      this.data.data = response.data;

    });
  }

  public onExport()
  {
    this.adminService.exportTransaction(this.current_filter).subscribe(e => {

      this.adminService.downloadTransaction(e.filename);
    })
  }


}
