import {NgModule} from '@angular/core';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    DateAdapter,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS,
} from '@angular/material';

import {MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MatFileUploadModule} from "../libraries/mat-file-upload/mat-file-upload.component";
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

export const MY_FORMATS = {
    parse: {
      dateInput: 'YYYY-MM-DD',
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'YYYY-MM-DD',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
  

@NgModule({
    imports: [
        MatDatepickerModule,
        ScrollingModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatGridListModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatSliderModule,
        MatRadioModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatRippleModule,
        MatProgressBarModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatFileUploadModule,
        DragDropModule
    ],
    exports: [
        MatDatepickerModule,
        ScrollingModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatGridListModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatSliderModule,
        MatRadioModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatTooltipModule,
        MatExpansionModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatRippleModule,
        MatProgressBarModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatFileUploadModule,
        DragDropModule
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
          },
          {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}

    ]
})
export class MaterialModule { }
