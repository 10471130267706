import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuardAdminService implements CanActivate, CanLoad {


    constructor(private authenticationService: AuthenticationService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkLogin(state.url);
    }
    canLoad(route: Route): Observable<boolean>| boolean {
        return this.checkLogin(location.pathname);
      }

      checkLogin(url: string): Observable<boolean> | boolean {
        if (this.authenticationService.isLogged && this.authenticationService.user.isAdmin)
            return true;
        return false;
    }
}
