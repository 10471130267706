import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { QuotasService } from 'src/app/services/quotas.service';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { map, catchError } from 'rxjs/operators';
import { of, Subject, forkJoin } from 'rxjs';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { RuleComponent } from './rule/rule.component';
import { DeleteDialogComponent } from './delete/delete-dialog.component';

declare var google: any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {


  private dataLength:number = 0;
  private columnTemp:Array<any>;
  private quotas:any = {};
  private isCanDeclare = false;
  public totalQuotas = 0;
  private key_bar = [];
  private data:MatTableDataSource<any> = new MatTableDataSource([]);
  public displayedColumns = {
    date : 'Date',
    'type': 'Type',
    'quotas': "Quotas",

  };
  private subject:Subject<any> = new Subject<any>();
  private chartDisplay : any = {
    'reunion': 'CSE',
    'preparation': 'CSE',
    'give_cse': 'Don CSE',
    'give_ds': 'Don DS',
//    'give_treasurer': 'Don Trésosier',

'ds': 'DS',
'annual_ds': 'Annuel DS',
'cse': 'CSE',
    'static_cse' : 'CSE',
    'treasurer' : 'Trésorier',
    'assistant_treasurer' : 'Trésorier adj.',
    'secretary_cse' : 'Secrétaire CSE',
    'assistant_secretary_cse' : 'Secrétaire CSE adj.',
    'secretary_cssct' : 'Secrétaire CSSCT',
    'assistant_secretary_cssct' : 'Secrétaire CSSCT adj.',
    'cssct' : 'CSSCT',
    'rscse' : 'RSCSE',
    'com_eg_pro' : 'Commission égalité pro (Annuel)',
    'com_form' : 'Commission Formation (Annuel)',
    'com_carriere': 'Commission carrière (Annuel)',
    'com_eco' : 'Commission Eco (Annuel)'
  }

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild("chart", {static:true})  chart:ElementRef;

  constructor(
    public quotasService:QuotasService,
    public authService:AuthenticationService,
    public dialog:MatDialog,
    public snackbar:MatSnackBar
  ) {

   }

   private drawChart(quotas){
    let chart = new google.visualization.PieChart(this.chart.nativeElement);
    let data = new google.visualization.DataTable();
    data.addColumn('string', 'Quotas');
    data.addColumn('number', 'Quantity');

    let cat = {};
    this.totalQuotas = 0;
    for (let t in quotas)
    {
      if (!quotas[t] || !(t in this.chartDisplay))
        continue;
      if (!(this.chartDisplay[t] in cat))
        cat[this.chartDisplay[t]] = 0;
        cat[this.chartDisplay[t]] += Math.abs(quotas[t]);
      this.totalQuotas +=  Math.abs(quotas[t]);
    }
    let rows = [];
    for (let r in cat)
      rows.push([r, cat[r]]);
    data.addRows(rows);
    let options = {
      pieHole: 0.6,
      pieSliceTextStyle: {
        color: '#4D4F5C',
      },
      pieSliceText: "none",
      height:300,
      colors: [
        //blue
        '#60DDFE',
        //fish
        '#FF8E7E',
        //violet
        '#ACAAFB',
        //yellow,
        '#FFDE8E'
      ]
    };
    chart.draw(data, options);
  }
  ngOnInit() {
    if (!this.authService.user.accept && !localStorage.getItem('accept'))
      this.dialog.open(RuleComponent, {autoFocus: false, disableClose: true});
    //this.quotasService.try().subscribe(e => console.log(e));
    this.columnTemp = Object.keys(this.displayedColumns);
    this.loadData();
    this.data.paginator = this.paginator;
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(() => {
      this.subject.next({});
      this.subject.complete()

    });
  }

  public buildKeyBar()
  {
    this.key_bar = [];
    for (let key in this.quotas.total)
    {
      if (this.quotas.quotas[key])
        this.isCanDeclare = true;

       if (key == 'static_cse')
         continue;
      this.key_bar.push(key);
    }
  }

  public onDelete(id:number)
  {
    let dial = this.dialog.open(DeleteDialogComponent, {autoFocus: false, disableClose: true});
    dial.afterClosed().subscribe(e => {
      if (e == true)
      {
        this.quotasService.remove(id).subscribe(e => {
          this.quotasService.list().pipe(map((response:any) => {
            this.dataLength = response.transactions.length;
            response.transactions = response.transactions.map(e => {
                
                e.date = moment.utc(e.date).format('YYYY-MM-DD');
                if (e.type.indexOf('give')!= 0)
                e.canUpdate = moment.utc().subtract(7, 'day').isBefore(moment.utc(e.date));
                e.type = this.chartDisplay[e.type];
                if (e.by && e.by.id != +this.authService.user.id && this.columnTemp.indexOf("substitute") === -1)
                  this.columnTemp.push('substitute');
                if (e.by && e.by.id == +this.authService.user.id && this.columnTemp.indexOf("of") === -1)
                  this.columnTemp.push('of');
                if(e.from && this.columnTemp.indexOf("from") === -1)
                  this.columnTemp.push('from');
                if (e.to && this.columnTemp.indexOf("to") === -1)
                  this.columnTemp.push('to');
                if (e.guest && this.columnTemp.indexOf('guest') === -1)
                  this.columnTemp.push('guest');
      
                e.quotas =  Object.values(e['quotas'])[0];
                return e;
      
            });
            if (this.columnTemp.indexOf("action") === -1)
              this.columnTemp.push('action');
            return response;
          }), catchError(() =>  of([]))).subscribe( (data ) => {
            this.data.data = data.transactions;
            if (data.canSubstitutes)
            this.isCanDeclare = true;
            this.drawChart(data.used_quotas_year);
            this.quotas = { quotas : data.quotas, amount: data.amount, total : data.total};
            this.buildKeyBar();
            this.data.paginator = this.paginator;
            this.snackbar.open("Annulation validée", 'FERMER', {duration: 5000});

          });
                });
      }
    })
  }


  public loadData()
  {
    forkJoin([this.quotasService.list().pipe(map((response:any) => {
      this.dataLength = response.transactions.length;
      response.transactions = response.transactions.map(e => {
          
          e.date = moment.utc(e.date).format('YYYY-MM-DD');
          if (e.type.indexOf('give')!= 0)
          e.canUpdate = moment.utc().subtract(7, 'day').isBefore(moment.utc(e.date));
          e.type = this.chartDisplay[e.type];
          if (e.by && e.by.id != +this.authService.user.id && this.columnTemp.indexOf("substitute") === -1)
            this.columnTemp.push('substitute');
          if (e.by && e.by.id == +this.authService.user.id && this.columnTemp.indexOf("of") === -1)
            this.columnTemp.push('of');
          if(e.from && this.columnTemp.indexOf("from") === -1)
            this.columnTemp.push('from');
          if (e.to && this.columnTemp.indexOf("to") === -1)
            this.columnTemp.push('to');
          if (e.guest && this.columnTemp.indexOf('guest') === -1)
            this.columnTemp.push('guest');

          e.quotas =  Object.values(e['quotas'])[0];
          return e;

      });
      this.columnTemp.push('action');
      return response;
    }), catchError(() =>  of([]))), this.subject])
     .subscribe( ([data , _]) => {
        this.data.data = data.transactions;
        if (data.canSubstitutes)
        this.isCanDeclare = true;
        this.drawChart(data.used_quotas_year);
        this.quotas = { quotas : data.quotas, amount: data.amount, total : data.total};
        this.buildKeyBar();
        this.data.paginator = this.paginator;
      });

  }

}
